<template>
    <div></div>
  </template>
  
  <script>
  import wx from 'weixin-js-sdk';
  import { pay } from "@/api/credit"
  import { Toast } from 'vant';
  
  export default {
    data() {
      return {
        theRequest: null,
        count: 1,
        openid: '',
      };
    },
    mounted() {
      let url = location.search
      let theRequest = {};
      if (url.indexOf("?") !== -1) {
        let str = url.substr(1)
        let strs = str.split("&")
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
        this.theRequest = theRequest;
      }
      this.handlePay();
      // Toast.loading({
      //   message: '调用微信支付中，请稍等!',
      //   forbidClick: true
      // })
      // pay({ code: theRequest.code, order_num: theRequest.state }).then(response => {
      //   wx.config({
      //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      //     appId: response.appId, // 必填，公众号的唯一标识
      //     timestamp: response.timeStamp, // 必填，生成签名的时间戳
      //     nonceStr: response.nonceStr, // 必填，生成签名的随机串
      //     signature: response.signature,// 必填，签名
      //     jsApiList: ['chooseWXPay', 'closeWindow'] // 必填，需要使用的JS接口列表
      //   });
      //   wx.ready(function () {
      //     wx.chooseWXPay({
      //       appId: response.appId,
      //       timestamp: response.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      //       nonceStr: response.nonceStr, // 支付签名随机串，不长于 32 位
      //       package: response.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
      //       signType: response.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      //       paySign: response.paySign, // 支付签名
      //       success: function () {
      //         Toast.success("支付成功")
      //         wx.closeWindow()
      //       },
      //       cancel: function () {
      //         Toast.fail("取消支付")
      //         this.$router.go(-1)
      //       },
      //       fail: function () {
      //         Toast.fail('支付失败，请返回上一页，重新下单')
      //       }
      //     });
      //   });
      // }).catch(() => {
      // })
    },
    methods: {
      handlePay() {
        Toast.loading({
          message: '调用微信支付中，请稍等!',
          forbidClick: true,
          duration: 0,
        })
        let _this = this;
        let theRequest = this.theRequest;
        pay({ code: theRequest.code, order_num: theRequest.state, count: _this.count, openid: _this.openid }).then(response => {
          Toast.clear(true)
          console.log(response)
          _this.count = 1;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: response.appId, // 必填，公众号的唯一标识
            timestamp: response.timeStamp, // 必填，生成签名的时间戳
            nonceStr: response.nonceStr, // 必填，生成签名的随机串
            signature: response.signature,// 必填，签名
            jsApiList: ['chooseWXPay', 'closeWindow'] // 必填，需要使用的JS接口列表
          });
          wx.ready(function () {
            wx.chooseWXPay({
              appId: response.appId,
              timestamp: response.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: response.nonceStr, // 支付签名随机串，不长于 32 位
              package: response.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
              signType: response.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
              paySign: response.paySign, // 支付签名
              success: function () {
                Toast.success("支付成功")
                setTimeout(function () {
                  window.history.go(-1);
                }, 500)
                //   wx.closeWindow()
              },
              cancel: function () {
                Toast.fail("取消支付")
                setTimeout(function () {
                  window.history.go(-1);
                }, 500)
                //   this.$router.go(-1)
              },
              fail: function () {
                Toast.fail('支付失败，请返回上一页，重新下单')
              }
            });
          });
        }).catch((e) => {
          console.log(e)
          Toast.clear(true)
          _this.count = e.data.count;
          _this.openid = e.data.openid;
          if (e.code == 504 && e.data.count < 10) {
            _this.handlePay()
          } else {
            _this.count = 1;
            Toast.fail(e.message)
          }
        })
  
      }
    }
  }
  </script>
  
  <style scoped>
  </style>